// src/GameComponent.js
import React, { useEffect, useRef } from 'react';
import Phaser from 'phaser';
import { addPipe, hitPipe } from './function/fnAddPipe';

const GameComponent = () => {
    const gameContainerRef = useRef(null);

    useEffect(() => {
        const config = {
            type: Phaser.AUTO,
            width: 800,
            height: 600,
            physics: {
                default: 'arcade',
                arcade: {
                    gravity: { y: 300 },
                    debug: true
                }
            },
            scene: {
                preload: preload,
                create: create,
                update: update
            },
            parent: gameContainerRef.current
        };

        const game = new Phaser.Game(config);

        function preload() {
            this.load.image('pipe', 'assets/pipe.png');
            this.load.image('bird', 'assets/bird.png');
        }

        function create() {



            this.bird = this.physics.add.sprite(100, 450, 'bird');
            this.bird.setDisplaySize(90, 90);
            this.bird.setCollideWorldBounds(true);
            this.bird.setGravityY(1000);

            this.input.on('pointerdown', () => {
                this.bird.setVelocityY(-400);
            });


            this.input.on('pointerdown', () => {
                this.bird.setVelocityX(400);
            });
            this.input.keyboard.on('keydown-W', (e) => {
                console.log(e)
                this.bird.setVelocityX(0);
            });

 
        }

        function update() {

 

        }

        return () => {
            game.destroy(true);
        };
    }, []);

    return <div ref={gameContainerRef} style={{ width: '800px', height: '600px' }} />;
};

export default GameComponent;
